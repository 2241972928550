<template>
  <div class="about">
    <!-- <div class="debg" :style="debgpageImg"></div> -->
    <div class="dedetail" v-for="Ison in list" :key="Ison.id">
      <div class="title">
        {{ Ison.title }}
      </div>
      <iframe
        style="position：absolute"
        id="particul"
        frameborder="0"
        width="100%"
        height="100%"
        scrolling="no"
        :src="aa"
      >
      </iframe>
    </div>
  </div>
</template>
<script>
var oldh;
oldh = 0;
export default {
  name: "About",
  data() {
    return {
      list: [],
      listimg: [],
      coverImg: "",
      debgpageImg: {
        backgroundImage: "",
      },
      aa: "",
    };
  },
  created() {
    this.getAbout();
  },
   methods: {
    async getAbout() {
      this.axios;
      var id = this.$route.params.id;
      const Image = await this.axios.get("/api/nav/menu/info/" + id);
      this.coverImg = Image.data.info.coverImg;
      this.debgpageImg.backgroundImage =
        "url(" + this.axios.defaults.baseURL + this.coverImg + ")";
      const res = await this.axios.get("/api/nav/menu/info/list", {
        params: {
          navMenuId: id,
          page: 1,
          limit: 1,
        },
      });
      this.list = res.data.page.list;
      console.log("this.list:" + JSON.stringify(this.list));
      this.aa = this.list[0].particulars;
      console.log("this.aa:" + this.aa);
      this.$nextTick(function () {
      document
          .getElementById("particul")
          .contentWindow.document.write(this.aa);
      });
      this.timer = setInterval(() => {
        this.getHeig();
      }, 300);
      //this.getHeig();
    },
    getHeig() {
      //this.$nextTick(function () {
        //或者Vue.nextTick
        
        var iframe = document.getElementById("particul");
        iframe.height = iframe.contentWindow.document.body.scrollHeight;//+200;
        if (oldh > 0 && oldh == iframe.height) {
          //  alert("pp");
          clearInterval(this.timer); //高度不再增长后退出
        }
        console.log("iframe.height:" + iframe.height);
        oldh = iframe.height;
     // });
    },
  },
};
</script>
<style>
.debg {
  position: absolute;
  top: 150px;
  width: 100%;
  margin: auto;
  left: 0;
  right: 0;
  z-index: 100;
  height: 700px;
  background-repeat: no-repeat;
  overflow: hidden;
}
.dedetail {
  width: 1286px;
  /* margin: 700px auto; */
    margin: 0 auto;
    min-height: 747px;
  margin-bottom: 0px;
}
.dedetail .title {
  padding-top: 60px;
  font-size: 50px;
  text-align: center;
  color: black;
  margin-bottom: 30px;
}
</style>